<template>
  <span class="multiple-unit-input">
    <unit-input :unit="unit" :value="val" @input="inputVal" v-bind="$attrs" :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder.replace('mm', '') : $attrs.placeholder" />
    <div class="drop" @mouseleave="showDropMenu=false" @mouseenter="showDropMenu=true">
      <span>
        <span class="unit">{{unit}}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <div class="drop-menu" v-show="showDropMenu">
        <div class="drop-menu-item" v-for="item in unitArr" :key="item" @click="handleDropItem(item)">{{item}}</div>
      </div>
    </div>
  </span>
</template>
<script>
/**
 * @component - 为了行间距可以多单位切换
 * @desc - 单位是字的时候转换成数字，单位是mm用字符串
 */
import UnitInput from './UnitInput';
export default {
  name: 'format-input',
  components: {
    UnitInput
  },
  props: {
    importUnit: {
      type: String
    },
    value: {
      required: true
    },
  },
  data () {
    return {
      val: '',
      unit: 'mm',
      unitArr: ['mm', '字'],
      showDropMenu: false
    };
  },
  methods: {
    handleDropItem (val) {
      this.unit = val;
      this.showDropMenu = false;
      this.inputVal(this.val);
    },
    inputVal (val) {
      if (val === '') {
        this.val = '';
      } else {
        const temp = parseFloat(val).toFixed(1);
        switch (this.unit) {
        case '字':
          this.val = +temp;
          break;
        case 'mm':
          this.val = +temp + 'mm';
          break;
        }
      }
      this.$emit('input', this.val);
    }
  },
  watch: {
    importUnit: {
      immediate: true,
      handler (val) {
        if (val) this.unit = val;
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val === '') {
          this.val = val;
          return;
        }
        if (typeof val === 'number') {
          this.val = val;
          this.unit = '字';
        } else {
          const num = parseFloat(val);
          if (num === 0 || num) {
            this.val = num;
          } else {
            this.val = '';
          }
          this.unit = 'mm';
        }
      }
    },
    '$attrs.placeholder': {
      immediate: true,
      deep: true,
      handler (val) {
        if (this.val || this.val === 0 || this.importUnit) return;
        if (typeof val === 'number') {
          this.unit = '字';
        } else {
          this.unit = 'mm';
        }
      }
    }
  }

};
</script>
<style scoped lang="scss">
.multiple-unit-input {
  /deep/ .el-input {
    width: 50px;
  }
  .drop {
    display: inline-block;
    position: relative;
    color: #ccc;
    .unit {
      font-size: 12px;
      color: #ccc;
      padding-left: 4px;
    }
    .el-icon--right {
      margin-left: 2px;
    }
    .drop-menu {
      position: absolute;
      right: 0;
      z-index: 2;
      background-color: #fff;
      min-width: 40px;
      color: #616366;
      .drop-menu-item {
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        cursor: pointer;
        font-weight: 500;
        padding: 0 4px;
        &:hover {
          background-color: #e5f4ff;
        }
      }
    }
  }
}
</style>
