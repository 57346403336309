<template>
  <span class="unit-number-input">
    <el-input type="number" v-model="val" @change="emitInput" :min="$attrs.min || '-1000'" :max="$attrs.max || '1000'" :step="$attrs.step || '0.1'" v-bind="$attrs" />
  </span>
</template>
<script>
export default {
  name: 'unit-input',
  props: {
    unit: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: ''
    };
  },
  methods: {
    emitInput () {
      let val;
      if (this.required) {
        val = (this.val || 0) + this.unit;
      } else {
        val = this.val === '' ? this.val : (this.val + this.unit);
      }
      this.$emit('input', val);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        const num = parseFloat(val);
        if (num === 0 || num) {
          this.val = num;
        } else {
          this.val = '';
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.unit-number-input {
  margin-left: 4px;
  /deep/ .el-input {
    width: 60px;
    font-size: 12px;
    input {
      height: 17px;
      line-height: 17px;
      padding: 0 4px;
    }
    /deep/ .el-input__inner {
      color: #333;
    }
    /deep/ .el-input__clear {
      line-height: 18px;
    }
    /deep/ .el-input__suffix {
      right: 12px;
    }
  }
}
</style>
