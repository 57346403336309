<template>
  <el-card class="book-core">
    <div class="body">
      <el-form :model="data" label-width="120px" class="form" size="mini">
        <!-- <el-form-item label="1.成品尺寸：">
          <el-row class="m-b-10">
            <el-col :span="12" class="input-box">
              <span class="label">高</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_size.height)" class="input-number" @input="intputPageSize($event, 'height')"></el-input>
              <span class="unit">mm</span>
            </el-col>
            <el-col :span="12" class="input-box">
              <span class="label">宽</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_size.width)" class="input-number" @input="intputPageSize($event, 'width')"></el-input>
              <span class="unit">mm</span>
            </el-col>
          </el-row>
          <el-row class="pop-container">
            <div class="select-box" :class="{ 'act-select-box': paperTypeOpen }">
              <div class="icon-box" v-show="false">
                <i class="el-icon-caret-right icon" @click="paperTypeOpen = !paperTypeOpen"></i>
              </div>
              <div class="item" v-for="item in computedPageSizes" :key="item.name" @click="changePageSize(item)"
                :class="{'act-item': item.name === actPageSize}"
              >
                <span class="type">{{ item.name}}</span>
                <span class="size" v-if="item.width && item.height">{{item.height}} x {{item.width}}</span>
              </div>
              </div>
          </el-row>
        </el-form-item> -->
        <el-form-item label="1.版心宽高：">
          <el-row class="m-b-10">
            <el-col :span="12" class="input-box">
              <span class="label">高</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_core_size.height)" class="input-number" @input="intputPageCoreSize($event, 'height')"></el-input>
              <span class="unit">mm</span>
            </el-col>
             <el-col :span="12" class="input-box">
              <span class="label">宽</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_core_size.width)" class="input-number" @input="intputPageCoreSize($event, 'width')"></el-input>
              <span class="unit">mm</span>
            </el-col>
          </el-row>
          <el-row class="select-box" style="padding-right: 10px; flex-wrap: nowrap;">
            <div class="item" v-for="item in computedPageCoreSize" :key="item.name" @click="changePageCoreSize(item)"
                :class="{'act-item': item.name === actPageCoreSize}">
              <span class="type">{{ item.name}}</span>
              <span class="size" v-if="item.width && item.height">{{item.height}} x {{item.width}}</span>
            </div>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="3.上空下空：">
          <el-row class="connection-box">
            <div class="connection-style">
              <svg-icon name="chain" class="icon-connection"></svg-icon>
              <span
                @click="autoSetMargin('v')"
                class="btn-auto-center c"
                >
                自动找齐居中
              </span>
            </div>
            <div class="input-box">
              <span class="label m-b-10">上空</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_margin.top)" class="input-number" @input="inputPageMarginSize($event, 'top')"></el-input>
              <span class="unit">mm</span>
            </div>
            <div class="input-box">
              <span class="label">下空</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_margin.bottom)" class="input-number" @input="inputPageMarginSize($event, 'bottom')"></el-input>
              <span class="unit">mm</span>
            </div>
          </el-row>
        </el-form-item>
        <el-form-item label="4.订口切口：">
          <el-row class="connection-box">
            <div class="connection-style">
              <svg-icon name="chain" class="icon-connection"></svg-icon>
              <span
                @click="autoSetMargin('h')"
                class="btn-auto-center c"
                >
                自动找齐居中
              </span>
            </div>
            <div class="input-box m-b-10">
              <span class="label">订口</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_margin.left)" class="input-number" @input="inputPageMarginSize($event, 'left')"></el-input>
              <span class="unit">mm</span>
            </div>
            <div class="input-box">
              <span class="label">切口</span>
              <el-input type="number" :value="parseFloat(data.page_cfg.page_margin.right)" class="input-number" @input="inputPageMarginSize($event, 'right')"></el-input>
              <span class="unit">mm</span>
            </div>
          </el-row>
        </el-form-item> -->
        <el-form-item label="2.分栏配置：">
          <el-row class="connection-box" style="width: unset">
                <el-checkbox label="是否使用方正代码进行配置" :value="data.page_cfg.global_column_fbd.enable_fbd" @input="data.page_cfg.global_column_fbd.enable_fbd = !data.page_cfg.global_column_fbd.enable_fbd"></el-checkbox>
          </el-row>
          <el-row class="connection-box" style="width: unset" v-show="data.page_cfg.global_column_fbd.enable_fbd">
            <div class="input-box m-b-10">
              <span class="label">最小栏宽</span>
              <format-input type="number" v-model="data.page_cfg.global_column_fbd.min_column_width" class="core-format-input" />
            </div>
            <FbdEditor
              v-model="data.page_cfg.global_column_fbd.code_template"
              :options="{ height: 150 }"
            />
          </el-row>
          <el-row class="connection-box" style="width: unset" v-show="!data.page_cfg.global_column_fbd.enable_fbd">
            <div class="input-box m-b-10">
              <span class="label">栏数</span>
              <el-input type="number" v-model="data.page_cfg.global_column.column_count" min="1" class="input-number" @input="columnCountInput" @change="columnCountChange"></el-input>
            </div>
            <div class="input-box m-b-10">
              <span class="label">栏宽</span>
              <format-input type="number" :value="data.page_cfg.global_column.column_gap" @input="columnGapInput" class="core-format-input" />
            </div>
            <div class="input-box m-b-10">
              <span class="label">栏线</span>
              <el-select v-model="data.page_cfg.global_column.line_type" :popper-append-to-body="false" clearable class="input-number">
                <el-option
                  v-for="(value, key) in columnLineType"
                  :key="value"
                  :label="key"
                  :value="value">
                </el-option>
              </el-select>
            </div>
            <div class="input-box m-b-10">
              <span class="label">线宽</span>
              <el-select v-model="data.page_cfg.global_column.line_weight" clearable class="input-number">
                <el-option
                  v-for="item in 7"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="input-box m-b-10">
              <span class="label">颜色</span>
              <Color :value="data.page_cfg.global_column.line_color" defaultColor="rgb(0, 0, 0)" class="m-t-5 m-l-10" :globalColor="styleData.book_default_style.global_color" />
            </div>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="6.PRO文件方正配置">
          <FbdEditor
            v-model="data.page_cfg.pro_fbd.code_template"
            :options="{ height: 150 }"
          />
        </el-form-item> -->

      </el-form>
      <div class="preview" ref="preview">
        <div class="page" :style="pageStyle">
          <div class="page-hint">成品尺寸： {{data.page_cfg.page_size.height}} x {{data.page_cfg.page_size.width}}</div>
          <div class="margin-top" :style="{ height: marginStyle.top }"><div class="hint">上空（{{data.page_cfg.page_margin.top}}）</div></div>
          <div class="margin-center">
            <div class="margin-left" :style="{ width: marginStyle.left }">
              <div class="hint">订口（{{data.page_cfg.page_margin.left}}）</div>
            </div>
            <div class="page-core">
              <div class="page-column" v-for="(item, index) in data.page_cfg.global_column.column_count" :key="item" :style="columnGap">
                <div class="page-column-line" :style="columnLine" v-if="index !== 0"></div>
              </div>
              <div class="page-core-text">
                <div>版心</div>
                <div>{{data.page_cfg.page_core_size.height}} x {{data.page_cfg.page_core_size.width}}</div>
              </div>
            </div>
            <div class="margin-right" :style="{ width: marginStyle.right }">
              <div class="hint">切口（{{data.page_cfg.page_margin.right}}）</div>
            </div>
          </div>
          <div class="margin-bottom" :style="{ height: marginStyle.bottom }">
            <div class="hint">下空（{{data.page_cfg.page_margin.bottom}}）</div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import Color from '../components/Color';
import FormatInput from '../components/FormatInput';
import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;
export default {
  components: {
    Color,
    FormatInput,
    FbdEditor
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.computePreview();
  },
  created () {
    this.data = this.styleData.components[0];
    // 加入默认的方正配置
    if (!this.data.page_cfg.pro_fbd) {
      this.$set(this.data.page_cfg, 'pro_fbd', { code_template: '' });
    }
    if (!this.data.page_cfg.global_column_fbd) {
      this.$set(this.data.page_cfg, 'global_column_fbd', { enable_fbd: false, min_column_width: '', code_template: '' });
    }
    if (!this.data.page_cfg.global_column_fbd.code_template) {
      this.$set(this.data.page_cfg.global_column_fbd, 'code_template', '');
    }

    const { page_cfg: { page_size: { width }, page_core_size: { width: coreWidth } } } = this.data;
    this.intputPageSize(parseFloat(width).toString(), 'width');
    this.intputPageCoreSize(parseFloat(coreWidth).toString(), 'width');
  },
  data () {
    return {
      data: {
        page_cfg: {
          page_size: {
            height: '297',
            width: '210',
            name: 'A4'
          },
          page_core_size: {
            height: '277',
            width: '190'
          },
          page_margin: {
            top: '10',
            right: '10',
            bottom: '10',
            left: '10'
          }
        }
      },
      columnLineType: {
        '无': 'none',
        '实线': 'solid',
        '短虚线': 'dashed',
        '双实线': 'double'
      },
      transformScale: '',
      paperTypeOpen: false,
      actPageSize: 'A4',
      actPageCoreSize: '标准',
      pageSizes: [
        { height: '297', width: '420', name: 'A3' },
        { height: '297', width: '210', name: 'A4' },
        { height: '353', width: '250', name: 'B4' },
        { height: '250', width: '176', name: 'B5' },
        { height: '390', width: '271', name: '8开' },
        { height: '374', width: '262', name: '大8开' },
        { height: '271', width: '195', name: '16开' },
        { height: '285', width: '210', name: '大16开' },
        { height: '195', width: '135', name: '32开' },
        { height: '203', width: '140', name: '大32开' },
        { height: '135', width: '97', name: '64开' },
        { name: '自定义' },
      ]
    };
  },
  computed: {
    columnGap () {
      const { global_column: { column_gap } } = this.data.page_cfg;
      return {
        marginLeft: parseFloat(column_gap) * this.transformScale + 'px'
      };
    },
    columnLine () {
      const { global_column: { line_type, line_color, column_gap } } = this.data.page_cfg;
      const style = {
        borderLeftColor: 'rgb(0, 0, 0)',
        left: -parseFloat(column_gap) * this.transformScale / 2 + 'px'
      };
      if (line_color?.rgb?.length === 3) {
        style.borderLeftColor = `rgb(${line_color.rgb.join(',')})`;
      }
      if (line_type === 'none' || !line_type) {
        style.display = 'none';
      } else {
        style.borderLeftStyle = line_type;
      }
      return style;
    },
    pageStyle () {
      if (!this.transformScale) return {};
      let { page_size: { width, height } } = this.data.page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      return {
        width: width * this.transformScale + 'px',
        height: height * this.transformScale + 'px'
      };
    },
    marginStyle () {
      if (!this.transformScale) return {};
      const { page_margin: { top, right, bottom, left } } = this.data.page_cfg;
      return {
        top: parseFloat(top) * this.transformScale + 'px',
        right: parseFloat(right) * this.transformScale + 'px',
        bottom: parseFloat(bottom) * this.transformScale + 'px',
        left: parseFloat(left) * this.transformScale + 'px'
      };
    },
    computedPageCoreSize () {
      let { page_size: { width, height } } = this.data.page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      return [
        { width: width - 6, height: height - 6, name: '小' },
        { width: width - 10, height: height - 10, name: '中' },
        { width: width - 20, height: height - 20, name: '标准' },
        { width: width - 30, height: height - 30, name: '大' },
        { width: width - 40, height: height - 40, name: '特大' },
        { name: '自定义' },
      ];
    },
    computedPageSizes () {
      const copyData = [...this.pageSizes];
      // const index = copyData.findIndex(item => item.name === this.actPageSize)
      // copyData.unshift(...copyData.splice(index, 1))
      return copyData;
    }
  },
  methods: {
    columnGapInput (val) {
      const { global_column } = this.data.page_cfg;
      if (val === '' || val < 0) {
        global_column.column_gap = typeof val === 'number' ? 0 : '0mm';
      } else {
        global_column.column_gap = val;
      }
    },
    columnCountInput (val) {
      const { global_column } = this.data.page_cfg;
      val = val.replace(/[^1-4]/g, '').slice(0, 1);
      global_column.column_count = val === '' ? '' : +val;
    },
    columnCountChange (val) {
      const { global_column } = this.data.page_cfg;
      if (val === '') {
        global_column.column_count = 1;
      }
    },
    computePreview () {
      const { offsetWidth, offsetHeight } = this.$refs.preview;
      let { page_size: { width, height } } = this.data.page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      const scale = width / height;
      if (scale > offsetWidth / offsetHeight) {
        this.transformScale = offsetWidth * 0.8 / width;
      } else {
        this.transformScale = offsetHeight * 0.8 / height;
      }
    },
    inputPageMarginSize (val, position) {
      const { page_margin, page_core_size, page_size } = this.data.page_cfg;
      // page_margin[position] = val.replace(/[^0-9]/g, '') + 'mm'
      if (val === '') {
        page_margin[position] = '';
        return;
      }
      page_margin[position] = Math.floor(val * 10) / 10 + 'mm';
      if (position === 'top') {
        page_margin.bottom = parseFloat(page_size.height) - parseFloat(page_core_size.height) - parseFloat(page_margin.top) + 'mm';
      } else if (position === 'bottom') {
        page_margin.top = parseFloat(page_size.height) - parseFloat(page_core_size.height) - parseFloat(page_margin.bottom) + 'mm';
      } else if (position === 'left') {
        page_margin.right = parseFloat(page_size.width) - parseFloat(page_core_size.width) - parseFloat(page_margin.left) + 'mm';
      } else {
        page_margin.left = parseFloat(page_size.width) - parseFloat(page_core_size.width) - parseFloat(page_margin.right) + 'mm';
      }
    },

    // 设置版心居中
    autoSetMargin (mark) {
      const { page_margin, page_core_size, page_size } = this.data.page_cfg;
      if (mark === 'v') {
        page_margin.top = page_margin.bottom = (parseFloat(page_size.height) - parseFloat(page_core_size.height)) / 2 + 'mm';
      }
      if (mark === 'h') {
        page_margin.left = page_margin.right = (parseFloat(page_size.width) - parseFloat(page_core_size.width)) / 2 + 'mm';
      }
    },

    intputPageCoreSize (val, position) {
      const { page_core_size, page_size, page_margin } = this.data.page_cfg;
      page_core_size[position] = val.replace(/[^0-9]/g, '') + 'mm';
      const curPageSize = this.computedPageCoreSize.find(item => item.width === parseFloat(page_core_size.width) && item.height === parseFloat(page_core_size.height));
      if (curPageSize) {
        this.actPageCoreSize = curPageSize.name;
      } else {
        this.actPageCoreSize = '自定义';
      }
      if (position === 'width') {
        page_margin.left = parseFloat(page_size.width) - parseFloat(page_core_size.width) - parseFloat(page_margin.right) + 'mm';
      } else {
        page_margin.bottom = parseFloat(page_size.height) - parseFloat(page_core_size.height) - parseFloat(page_margin.top) + 'mm';
      }
    },
    intputPageSize (val, position) {
      const { page_size } = this.data.page_cfg;
      page_size[position] = val.replace(/[^0-9]/g, '') + 'mm';
      const curPageSize = this.pageSizes.find(item => item.width === parseFloat(page_size.width) && item.height === parseFloat(page_size.height));
      if (curPageSize) {
        this.actPageSize = curPageSize.name;
      } else {
        this.actPageSize = '自定义';
      }
    },
    changePageCoreSize (item) {
      let { width: coreWidth, height: coreHeight, name } = item;
      coreWidth = parseFloat(coreWidth);
      coreHeight = parseFloat(coreHeight);
      this.actPageCoreSize = name;
      if (coreWidth && coreHeight) {
        let { page_cfg: { page_size: { width, height } }, page_cfg } = this.data;
        width = parseFloat(width);
        height = parseFloat(height);
        const brow = (height - coreHeight) / 2;
        const sidebar = (width - coreWidth) / 2;
        Object.assign(page_cfg, {
          page_core_size: {
            width: coreWidth + 'mm',
            height: coreHeight + 'mm'
          },
          page_margin: {
            top: brow + 'mm',
            right: sidebar + 'mm',
            bottom: brow + 'mm',
            left: sidebar + 'mm',
          }
        });
      }
    },
    changePageSize (item) {
      const { width, height, name } = item;
      this.actPageSize = name;
      this.actPageCoreSize = '标准';
      if (width && height) {
        const { page_cfg: { page_size, page_core_size, page_margin } } = this.data;
        Object.assign(page_size, {
          width: width + 'mm',
          height: height + 'mm',
          name: name
        });
        Object.assign(page_core_size, {
          width: width - 20 + 'mm',
          height: height - 20 + 'mm'
        });
        Object.assign(page_margin, {
          top: '10mm',
          right: '10mm',
          bottom: '10mm',
          left: '10mm'
        });
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.computePreview();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .book-core {
    overflow: auto;
    .core-format-input {
      margin: 0 10px;
      /deep/ .el-input {
        width: 140px;
        input {
          height: 28px;
          line-height: 28px;
          padding: 0 15px;
        }
      }
      /deep/ .unit {
        font-size: 14px;
        padding-left: 10px;
        color: #333;
      }

    }
    /deep/ .el-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .body {
      display: flex;
      justify-content: space-between;
      .form {
        flex: auto;
        min-width: 620px;
        max-width: 1000px;
        margin-right: 40px;
        .input-box {
          display: flex;
          .input-number {
            width: 140px;
            margin: 0 10px;
          }
        }
        .connection-box {
          width: 220px;
          position: relative;
          .connection-style {
            position: absolute;
            right: -20px;
            width: 20px;
            top: 14px;
            bottom: 14px;
            border: 1px solid #ccc;
            border-left-color: transparent;
            .icon-connection {
              position: absolute;
              right: -30px;
              top: 60%;
              transform: translate(0, -50%);
            }
            .btn-auto-center{
              width: 100px;
              position: absolute;
              right: -140px;
              top: 20%;
              cursor: pointer;
            }
          }
        }
        .icon-box {
          position: absolute;
          right: 0;
          top: 0;
          height: 90px;
          width: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          color: #ccc;

          .icon {
            cursor: pointer;
            transition: all .2s;
            &:hover {
              color: #333;
            }
          }
        }
        .pop-container {
          position: relative;
          // height: 90px;
          z-index: 1;
        }
        .select-box {
          background-color: #F5F6F7;
          position: relative;
          display: flex;
          overflow: hidden;
          flex-wrap: wrap;
          // height: 90px;
          padding: 10px 10px 10px 0;
          .item {
            width: 60px;
            height: 60px;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: none;
            justify-content: center;
            color: #666;
            cursor: pointer;
            margin: 0 0 10px 8px;
            .type {
              font-size: 14px;
            }
            .size {
              font-size: 12px;
            }
            &:hover {
              color: #409EFF;
              border-color: #409EFF;
            }
          }
          .act-item {
            color: #409EFF;
            border-color: #409EFF;
          }
        }
        .act-select-box {
          height: unset;
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          box-shadow: 4px 4px 10px #ccc;
          .icon {
            transform: rotate(90deg);
          }
        }
      }
      .preview {
        flex: none;
        width: 40%;
        min-width: 360px;
        background-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        * {
          transition: all 0.2s;
        }
        .page {
          background-color: #fff;
          border: 1px solid #ccc;
          display: flex;
          position: relative;
          flex-direction: column;
          font-size: 12px;
          color: #888;
          .margin-top, .margin-bottom {
            flex: none;
            position: relative;
            .hint {
              position: absolute;
              left: 50%;
              top: 0;
              transform: translate(-50%, 0);
            }
          }
          .margin-center {
            flex: auto;
            display: flex;
            .margin-right, .margin-left {
              position: relative;
              flex: none;
              .hint {
                width: 14px;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                transform: translate(0, -50%);
              }
            }
            .page-core {
              flex: auto;
              display: flex;
              border: 1px dashed #ccc;
              position: relative;
              .page-column {
                background-color: #e4e8ec;
                height: 100%;
                flex: 1;
                position: relative;
                .page-column-line {
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                }
                &:nth-of-type(1) {
                  margin-left: 0!important;
                }
              }
              .page-core-text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .page-hint {
            position: absolute;
            top: 0;
            left: 0;
            color: #333;
            font-size: 14px;
            transform: translate(0, -100%);
          }
        }
      }
    }
  }
</style>
